<template>
	<div class="app-main__inner">
		<div class="container mb-3">
			<div class="flex flex-col md:flex-row justify-between align-items-center mb-3">
				<div>
					<div class="flex justify-between align-items-center space-x-3 mb-3">
						<span class="text-sm md:text-2xl font-semibold text-gray-900">{{ $t('orders') }}</span>
					</div>
				</div>
				<div class="div-action order-switch">
					<el-switch
						@change="onToggleEvent"
						v-model="order_type_"
						:active-text="$t('invoice_')"
						:inactive-text="$t('order')"
					>
					</el-switch>
					<div
						class="flex justify-between align-items-center uppercase border rounded-md bg-white text-xs md:text-xl">
					<span @click="showTab(5)"
						  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-l-md"
						  :class="tab === 5 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('all')
						}}</span>
						<span @click="showTab(1)"
							  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 "
							  :class="tab === 1 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
								$t('delivered')
							}}</span>
						<span @click="showTab(2)"
							  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2"
							  :class="tab === 2 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
								$t('paid')
							}}</span>
						<span @click="showTab(3)"
							  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2"
							  :class="tab === 3 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
								$t('processing')
							}}</span>
						<span @click="showTab(4)"
							  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-r-md"
							  :class="tab === 4 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
								$t('cancelled')
							}}</span>
					</div>
				</div>

			</div>

			<div class="row flex justify-end mx-1">
				<div class="col-md-12 flex justify-center md:justify-end mb-2 bg-white border rounded-md p-2">
					<!--                    <span class="mr-2 md:mb-0 cursor-pointer" @click="showFilter = !showFilter">-->
					<!--                            <span-->
					<!--								class="flex justify-between align-items-center space-x-4 bg-gray-200 border px-3 py-2 rounded-md">-->
					<!--                                <span><img src="./../../assets/images/icons/Frame.png" alt=""></span>-->
					<!--                                <span class="text-gray-500">{{ $t('filter') }}</span>-->
					<!--                            </span>-->
					<!--                        </span>-->
					<!--					<div class="absolute w-60 bg-white rounded border shadow-lg md:-ml-10 md:mt-10"-->
					<!--						 style="z-index:9999; right: 157px !important;" v-if="showFilter">-->
					<!--						<FilterOrderComponent-->
					<!--							@hideAdvanceFilter="showFilter = false"-->
					<!--							@handleFilter='handleFiltering'-->
					<!--							@clearFilter='clearFilter'-->
					<!--						/>-->
					<!--					</div>-->
					<div
						class="flex flex-col md:flex-row justify-end align-items-center space-x-4 space-y-3 md:space-y-0">
                        <span class="">
                            <input type="text" v-model="searchName" @input="searchInputTrigger"
								   class="flex justify-between w-80 align-items-center space-x-4 focus:outline-none bg-gray-200 border px-3 py-2 rounded-md text-gray-500"
								   v-bind:placeholder="$t('search')">
                        </span>
					</div>
				</div>
			</div>

			<div class="row dm-sans-font">
				<div class="col-md-12 mt-3"> <!--pl-5 pr-5-->
					<div class="table-responsive rounded-lg bg-white border">
						<table v-loading='loading' style="width: 100%;" id="exampledestroy"
							   class="table font-light table-hover table-striped table-bordered">
							<thead>
							<tr>
								<!-- <th class="text-xs">{{ $t('customerName') }}</th> -->
								<th class="text-xs">
									<div class="d-flex justify-content-between">{{ $t('b2bOrderNumber') }}<span><i
										@click="sortOrder('in_house_order_no')" class="lnr-arrow-up cursor-pointer"></i><i
										@click="sortOrder('-in_house_order_no')"
										class="lnr-arrow-down cursor-pointer"></i></span>
									</div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between">{{ $t('customerName') }}<span><i
										@click="sortOrder('customer_name')" class="lnr-arrow-up cursor-pointer"></i><i
										@click="sortOrder('-customer_name')" class="lnr-arrow-down cursor-pointer"></i></span>
									</div>
								</th>

								<th class="text-xs">
									<div class="d-flex justify-content-between">{{ $t('orderNumber') }}<span><i
										@click="sortOrder('document_number')" class="lnr-arrow-up cursor-pointer"></i><i
										@click="sortOrder('-document_number')"
										class="lnr-arrow-down cursor-pointer"></i></span>
									</div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center"><span>{{
											$t('type')
										}}</span></div>
								</th>
								<!-- <th class="text-xs"><div class="d-flex justify-content-between"><span>Shop Name</span></div></th> -->
								<th class="text-xs">
									<div class="d-flex justify-content-between">{{ $t('orderDate') }}<span><i
										@click="sortOrder('order_date')" class="lnr-arrow-up cursor-pointer"></i><i
										@click="sortOrder('-order_date')"
										class="lnr-arrow-down cursor-pointer"></i></span>
									</div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between">{{ $t('orderStatus') }}<span><i
										@click="sortOrderStatusAsc" class="lnr-arrow-up cursor-pointer"></i><i
										@click="sortOrderStatusDesc" class="lnr-arrow-down cursor-pointer"></i></span>
									</div>
								</th>
								<!-- <th class="text-xs"><div class="d-flex justify-content-between"><span>{{ $t('orderStatus') }}</span></div></th> -->
								<th class="text-xs">
									<div class="d-flex justify-content-between">{{ $t('orderSummary') }}<span><i
										@click="sortOrder('total_to_pay')" class="lnr-arrow-up cursor-pointer"></i><i
										@click="sortOrder('-total_to_pay')"
										class="lnr-arrow-down cursor-pointer"></i></span>
									</div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between"><span>{{ $t('reference') }}</span></div>
								</th>
								<th class="text-xs">{{ $t('actions') }}</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="(order, i) in GET_ORDERS.data" :key="i">
								<td class="text-xs"><span v-if="order.in_house_order_no">{{
										order.in_house_order_no
									}}</span>
									<span v-else></span></td>
								<td class="text-xs">{{ order.customer_name }}</td>
								<td class="text-xs">{{ order.document_number }}</td>
								<!-- <td class="text-xs">{{ order.company.name }}</td> -->
								<td class="text-xs"><span
									v-if="order.order_type === 'Order'">{{ $t('order') }}</span><span
									v-else>{{ $t('invoice_') }}</span></td>
								<td class="text-xs">{{ order.order_date }}</td>
								<td class="text-xs">
									<p v-if="order.order_status === 'processing'"
									   class="p-1 bg-table-yellow rounded ">{{
											$t('_processing')
										}}</p>

									<p v-if="order.order_status === 'paid'"
									   class="p-1 bg-blue-800 text-white rounded ">{{ $t('paid') }}</p>
									<p v-if="order.order_status === 'delivered'" class="p-1 bg-table-green rounded">{{
											$t('delivered')
										}}</p>
									<p v-if="order.order_status === 'cancelled'" class="p-1 bg-table-red rounded ">{{
											$t('cancelled')
										}}</p>
									<!--			 {{ order.order_status }}-->
								</td>
								<td class="text-xs">{{ order.total_to_pay }} SEK
								</td>
								<td class="text-xs">
									{{ order.your_reference }}
								</td>
								<td>
                                        <span class="flex">
                                            <el-tooltip :content="$t('download')">
                                                <DownloadTableManager :data='order' :value='order.order_type'/>
                                            </el-tooltip>
											<span
												:title="order.order_type === 'invoice' ? $t('copyInvoice'): $t('copyOrder')"
												v-on:click="createOrderOrInvoiceCopy(order)"
												class="ml-1 mr-1 flex justify-center align-items-center  h-7 w-7  cursor-pointer order-icon">
										<img src="./../../assets/images/icons/duplicate.svg" alt="">
                                        </span>
                                            <span class="cursor-pointer ml-2" @click="openModalNote(order)">
                                                <svg width="19" height="19" viewBox="0 0 20 20" fill="none"
													 xmlns="http://www.w3.org/2000/svg">
<path
	d="M19.4811 2.80285L16.9956 0.317329C16.782 0.113634 16.4981 0 16.2029 0C15.9078 0 15.6239 0.113634 15.4103 0.317329L13.2002 2.53415H1.34353C0.987202 2.53415 0.64547 2.6757 0.39351 2.92766C0.14155 3.17962 0 3.52135 0 3.87768V18.6565C0 19.0128 0.14155 19.3545 0.39351 19.6065C0.64547 19.8584 0.987202 20 1.34353 20H16.1223C16.4787 20 16.8204 19.8584 17.0723 19.6065C17.3243 19.3545 17.4659 19.0128 17.4659 18.6565V6.40351L19.4811 4.38822C19.6912 4.17788 19.8091 3.89278 19.8091 3.59553C19.8091 3.29829 19.6912 3.01319 19.4811 2.80285ZM9.96225 12.0262L7.14756 12.6509L7.81933 9.86309L14.2347 3.43431L16.4045 5.60411L9.96225 12.0262ZM17.13 4.8383L14.9602 2.6685L16.2029 1.42574L18.3727 3.59553L17.13 4.8383Z"
	:fill="has_note_and_not_viewed(order)"/>
</svg>

                                            </span>
                                        </span>
								</td>
							</tr>
							<!-- <tr v-if="history.Invoices !== undefined && history.Invoices.length < 1" class="odd text-center"><td colspan="5" class="dataTables_empty text-xs" valign="top">{{ $t('noDataTable') }}</td></tr> -->
							</tbody>
						</table>
					</div>
					<div class="flex flex-col md:flex-row justify-between mb-4">
                        <span class="flex justify-between align-items-center space-x-5 mt-4">
                            <span class="text-base">{{ $t('show') }}</span>
                            <span>
                                <select v-model="limit" class="form-select" @change="shopResultNumber">
                                    <option value="10" selected>10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                </select>
                            </span>
                        </span>
						<div class="pagination-block" v-if="GET_ORDERS.data"> <!--GET_ARTICLES.total_pages--->
							<paginate
								:page-count="GET_ORDERS.total_pages"
								:click-handler="changePageNumber"
								prev-text="<"
								next-text=">"
								:container-class="'pagination space-x-2'">
							</paginate>
						</div>
					</div>
				</div>
				<!-- <div class="col-md-1"></div> -->
			</div>
		</div>


		<!-- <el-dialog
		:visible.sync="paymentCardModal"
		width="40%"
		:handleClose="handleClose"
		center>
		<div class="container">
			<div class="row">
				<div id="card-element" class="col-md-12">

				</div>
			</div>
		</div>
		</el-dialog> -->

		<Notification/>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import debounce from 'lodash.debounce'
import Paginate from 'vuejs-paginate'
import FilterOrderComponent from './component/FilterOrderComponent'
// import i18n from './../../plugins/i18n'
import DownloadTableManager from './../../customComponent/DownloadTableManager'

export default {
	name: 'order-page',
	components: {
		Notification: () => import('./../../customComponent/NotificationAlert'),
		paginate: Paginate,
		DownloadTableManager,
		FilterOrderComponent
	},
	data() {
		return {
			PaymentType: null,
			billing: {
				name: '',
				company_id: '',
				address: {
					address_line_one: '',
					zip_code: '',
					city: '',
					phone_mobile: '',
				},
				email: '',
				invoiceEmail: ''
			},
			Orders: [],
			history: [],
			copyHistory: [],
			parPage: 10,
			currentPage: 1,
			tab: 5,
			status: null,
			updating: false,
			showFilter: false,
			paymentCardModal: false,
			processingCard: false,
			processingInvoice: false,
			loading: true,
			searchName: '',
			pageNum: 1,
			limit: 10,
			company_id: 10,
			order_type: 'Order',
			order_type_: false
		}
	},
	watch: {
		limit: function (newVal, oldVal) {
			this.limit = newVal
			window.localStorage.setItem('ordernum', newVal)
		}
	},
	computed: {
		...mapGetters({
			GET_ORDERS: 'portal/GET_ORDERS'
		})
	},
	mounted() {
		const num = window.localStorage.getItem('ordernum')
		const {company_id} = this.$route.params
		this.company_id = company_id
		if (this.company_id === 'invalid_company') {
			this.$services.helpers.notification(this.$t('no_company_text'), 'error', this)
		} else {
			if (parseInt(num)) {
				this.limit = num
				this.getCompanyOrders(parseInt(num), this.status)
			} else {
				window.localStorage.setItem('ordernum', this.limit)
				this.getCompanyOrders(this.limit, this.status)
			}
		}

	},
	methods: {
		createOrderOrInvoiceCopy: function (order) {
			let self = this
			let order_type = order.order_type === 'invoice' ? this.$t('invoice') : this.$t('order')
			this.$confirm(`${this.$t('createOrderOrInvoiceCopyText')} ${order_type}?`, `${this.$t('info')}`, {
				confirmButtonText: this.$t('proceed'),
				cancelButtonText: this.$t('cancel'),
				type: 'warning',
				beforeClose: (action, instance, done) => {
					if (action === 'confirm') {
						instance.confirmButtonLoading = true
						instance.confirmButtonText = `${self.$t('proceed')}...`
						self.$store.dispatch('customer/createOrderInvoiceCopy', {id: order.id})
							.then(() => {

								this.$services.helpers.notification(`${order_type} ${self.$t('createSuccess')}`, 'success', this)
								done()
								instance.confirmButtonLoading = false
								instance.confirmButtonText = self.$t('proceed')
								this.getCompanyOrders(this.limit, this.status)
							}).catch((err) => {
							done()
							instance.confirmButtonLoading = false
							window.Bus.$emit('output-error', err)
						})
					} else {
						done()
					}
				}
			}).then(() => {

			})
				.catch(() => {

				})
		},
		onToggleEvent: function () {
			if (parseInt(this.tab) === 5) {
				this.status = null
			}
			if (this.order_type_ === true) {
				this.order_type = 'invoice'
			} else {
				this.order_type = 'Order'
			}
			this.getCompanyOrders(this.limit, this.status)
		},
		searchInputTrigger: debounce(function (event) {
			if (event.target.value !== '') {
				this.filterSearch(event)
			} else {
				this.getCompanyOrders(this.limit)
			}
		}, 500),
		filterSearch(event) {
			let page = ''
			if (this.status) {
				page = page + `?tab_status=${this.status}&limit=${this.limit}&order_type=${this.order_type}&company_id=${this.company_id}`
			} else {
				page = page + `?limit=${this.limit}&order_type=${this.order_type}&company_id=${this.company_id}`
			}
			if (this.searchName !== '') {
				page = page + `&search=${this.searchName}`
			}
			this.$store.dispatch('customer/getCompanyOrders', {page: page})
				.then(_ => {
					//
				}).catch((err) => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		changePageNumber(number) {
			let page = ''
			this.pageNum = number
			if (this.status) {
				page = `?page=${number}&tab_status=${this.status}&limit=${this.limit}&order_type=${this.order_type}&company_id=${this.company_id}`
			} else {
				page = `?page=${number}&limit=${this.limit}&order_type=${this.order_type}&company_id=${this.company_id}`
			}
			if (this.searchName !== '') {
				page = page + `&search=${this.searchName}`
			} else {
				this.searchName = ''
			}
			this.$store.dispatch('customer/getCompanyOrders', {page: page})
				.then(_ => {
					//
				}).catch((err) => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		sortOrder: function (sortValue) {
			let page = ''
			if (this.status) {
				page = `?page=${this.pageNum}&tab_status=${this.status}&limit=${this.limit}&order_type=${this.order_type}&company_id=${this.company_id}`
			} else {
				page = `?page=${this.pageNum}&limit=${this.limit}&order_type=${this.order_type}&company_id=${this.company_id}`
			}
			page += `&ordering=${sortValue}`
			if (this.searchName !== '') {
				page = page + `&search=${this.searchName}`
			} else {
				this.searchName = ''
			}
			this.$store.dispatch('customer/getCompanyOrders', {page: page})
				.then(_ => {
					//
				}).catch((err) => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})

		},
		getCompanyOrders(limit, status = null) {
			this.loading = true
			let page = `?limit=${limit}&company_id=${this.company_id}&order_type=${this.order_type}`

			if (status) {
				page = page + `&tab_status=${status}`
			}
			this.$store.dispatch('customer/getCompanyOrders', {page: page})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				window.Bus.$emit('output-error', err)
			})
		},

		handleFiltering(value) {
			let page = `?limit=${this.limit}&company_id=${this.company_id}`
			this.loading = true
			this.filterName = value
			if (this.filterName === 'invoice') {
				page = page + '&order_type=invoice'
				this.order_type = 'invoice'
			} else if (this.filterName === 'order') {
				page = page + '&order_type=Order'
				this.order_type = 'Order'
			}
			if (this.status) {
				page = page + `&tab_status=${this.status}`
			}
			this.$store.dispatch('customer/getCompanyOrders', {page: page})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				window.Bus.$emit('output-error', err)
			})
		},
		clearFilter() {
			this.loading = true
			this.showFilter = false
			this.filterName = ''
			this.getCompanyOrders(this.limit)
		},
		sortOrderNumberAsc() {
			this.$store.commit('portal/SORT_ORDER_NUMBER_ASC')
		},
		sortOrderNumberDesc() {
			this.$store.commit('portal/SORT_ORDER_NUMBER_DESC')
		},
		sortOrderDateAsc() {
			this.$store.commit('portal/SORT_ORDER_DATE_ASC')
		},
		sortOrderDateDesc() {
			this.$store.commit('portal/SORT_ORDER_DATE_DESC')
		},
		sortCustomerNameAsc() {
			this.$store.commit('portal/SORT_CUSTOMER_NAME_ASC')
		},
		sortB2bOrderNumberAsc() {
			this.$store.commit('portal/SORT_B2B_ORDER_NUMBER_ASC')
		},
		sortB2bOrderNumberDesc() {
			this.$store.commit('portal/SORT_B2B_ORDER_NUMBER_DESC')
		},
		sortCustomerNameDesc() {
			this.$store.commit('portal/SORT_CUSTOMER_NAME_DESC')
		},

		sortOrderStatusAsc() {
			this.$store.commit('portal/SORT_ORDER_STATUS_ASC')
		},
		sortOrderStatusDesc() {
			this.$store.commit('portal/SORT_ORDER_STATUS_DESC')
		},
		sortOrderSummaryAsc() {
			this.$store.commit('portal/SORT_ORDER_SUMMARY_ASC')
		},
		sortOrderSummaryDesc() {
			this.$store.commit('portal/SORT_ORDER_SUMMARY_DESC')
		},
		showTab(number) {
			this.tab = number
			if (this.tab === 1) {
				this.status = 'delivered'
				this.getCompanyOrders(this.limit, this.status)
			} else if (this.tab === 2) {
				this.status = 'paid'
				this.getCompanyOrders(this.limit, this.status)
			} else if (this.tab === 3) {
				this.status = 'processing'
				this.getCompanyOrders(this.limit, this.status)
			} else if (this.tab === 4) {
				this.status = 'cancelled'
				this.getCompanyOrders(this.limit, this.status)
			} else {
				this.status = null
				this.getCompanyOrders(this.limit, null)
			}
		},
		openModalNote(order) {
			window.Bus.$emit('open-modal-note', order)
		},
		shopResultNumber(e) {
			this.limit = parseInt(e.target.value)
			this.getCompanyOrders(parseInt(e.target.value))
		},
		has_note_and_not_viewed(order) {
			if (order.buyer_note === '') {
				return '#6b7280'
			}
			if (order.hasOwnProperty('is_buyer_handle_note')) {
				if (order.is_buyer_handle_note === false) {
					return 'orange'
				} else {
					return '#2CDCA7'
				}
			} else {
				return 'orange'
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.custom-btn-pay {
	background-color: #F167CA !important;
	border-color: #F167CA !important;
	font-weight: 800;
}

.custom-card-section {
	box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
	border-width: 0;
	transition: all .2s;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(26, 54, 126, 0.125);
}

.custom-save-btn {
	background-color: #2CDCA7 !important;
	border-color: #2CDCA7 !important;
	font-weight: 800;
}

.override-form-field {
	border: 0;
	border-bottom: 2px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: #fff !important;
	border-radius: unset !important;
}

.col-form-label {
	padding-top: 1rem !important;
	// font-size: 14px !important;
}

.form-group {
	margin-bottom: 0.4rem !important;
}

.form-input {
	font-size: 0.8rem !important;
	padding: 0.2rem 0.75rem !important;
}

.col-form-label {
	padding-bottom: unset !important;
	padding-top: 0.3rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

// .table td{
//     padding: 0.1rem 0.55rem !important;
// }
.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-select {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem 1.5rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}

.tab-btn.active {
	color: #181C4C !important;
	background-color: #fff !important;
}

.table tbody tr > td {
	font-size: 13px !important;
	color: #6F6F6F !important;
	padding: 0.6rem 0.55rem !important;
}

.div-action {
	display: flex;
	justify-content: space-around;
}

.order-icon {
	height: 17px;
}

@media only screen and (max-width: 640px) {
	.div-action {
		display: block;
		justify-content: normal;
	}
	.sm-center {
		display: table;
		margin: auto;
	}
	.show-lg-screen {
		display: none;
	}
	.show-sm-screen {
		display: block;
	}
	.row-sm {
		flex-direction: inherit !important;
	}
	.sm-order-action {
		overflow-x: scroll !important;
		overflow-y: hidden !important;
	}
}
</style>
